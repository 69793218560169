export default {
  "global": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Lists"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "enterQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a quantity"])},
    "confirmHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm !"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data"])},
    "cancelSharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel sharing"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "pages": {
    "lists": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Lists"])}
    },
    "week": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Week"])}
    }
  },
  "lists": {
    "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new list"])},
    "confirmDeleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this list ?"])},
    "confirmEmptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to empty this list ?"])},
    "keepSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep the sections"])},
    "confirmCancelShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel the sharing of this list"])},
    "successfullyCanceledShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sharing was canceled successfully"])}
  },
  "weeks": {
    "confirmCancelShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to stop sharing this week ?"])},
    "successfullyCanceledShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully canceled week sharing."])}
  },
  "sections": {
    "addNewSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new section"])},
    "confirmDeleteSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this section ?"])}
  },
  "items": {
    "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new item"])},
    "confirmDeleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sur you want to delete this item ?"])}
  },
  "errors": {
    "fieldCannotBeEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field cannot be empty."])},
    "userDoesntExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user does not exists"])}
  },
  "modals": {
    "shareInfo": {
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This this has been shared to you by <b>", _interpolate(_named("author")), "</b>. You cannot delete it, but you can make changes to it."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this is a shared list"])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created with love by Nicolas D'Amours <https://github.com/nicDamours>."])},
      "foundABug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You found a bug ? It happens."])},
      "reportIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open an issue!"])},
      "softwareCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This app is offered for free, because I'm using it and it doesn't cost me a dime to host. Enjoy !"])},
      "showSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to show your support, you can always"])},
      "buyMeACoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy me a coffee!"])},
      "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or you could contribute on"])}
    }
  },
  "shareWithUserModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share a list"])},
    "listTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List shared with"])},
    "revokeSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share revoke successfully"])},
    "successfullySentShareRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share request sent successfully"])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New share"])},
    "form": {
      "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To share this list with someone, please enter their email address below. Please note that they need an account."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])}
    }
  },
  "ShareWeekWithUserModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partage de semaine"])},
    "form": {
      "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To share all weeks with someone, please enter their email address below. Please note that they need an account."])}
    }
  },
  "shareRequest": {
    "item": {
      "list": {
        "textReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " wants to share the list \"", _interpolate(_named("listName")), "\" with you."])},
        "textSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Share request sent to ", _interpolate(_named("targetName")), " for the list \"", _interpolate(_named("listName")), "\"."])}
      },
      "week": {
        "single": {
          "textReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " wants to share the week starting on ", _interpolate(_named("startDate")), " to ", _interpolate(_named("endDate")), " with you."])},
          "textSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Share request sent to ", _interpolate(_named("targetName")), " for the week starting on ", _interpolate(_named("startDate")), " to ", _interpolate(_named("endDate")), "."])}
        },
        "all": {
          "textReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " wants to share all weeks with you"])},
          "textSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Share request sent to  ", _interpolate(_named("targetName")), " for all weeks."])}
        }
      },
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
      "revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])}
    },
    "successfullyAcceptedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share request accepted successfully"])},
    "successfullyDeclinedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share request declined successfully"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Request"])},
    "receivedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received requests"])},
    "issuedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent requests"])}
  },
  "tabs": {
    "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lists"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])}
  },
  "WeekPlanner": {
    "titles": {
      "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
      "supper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diner"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])}
    }
  },
  "shareWeekSelector": {
    "sharedWeek": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("email")), "'s week"])},
    "yourWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your week"])},
    "shareAllWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share all weeks"])},
    "shareSpecificWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share a single week"])}
  }
}