export default {
  "global": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Listes"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "enterQuantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer une quantité"])},
    "confirmHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée"])},
    "cancelSharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrêter le partage"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
  },
  "pages": {
    "lists": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes Listes"])}
    },
    "week": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma Semaine"])}
    }
  },
  "lists": {
    "addNewList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle liste"])},
    "confirmDeleteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette liste ?"])},
    "confirmEmptyList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vider cette liste ?"])},
    "confirmCancelShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir arrêter le partage de cette liste ?"])},
    "keepSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garder les sections."])},
    "successfullyCanceledShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partage à été arrêter avec succès"])}
  },
  "weeks": {
    "confirmCancelShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir arrêter ce partage de semaine?"])},
    "successfullyCanceledShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le partage de semaine à été arrêté avec succès."])}
  },
  "sections": {
    "addNewSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle section"])},
    "confirmDeleteSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cette section ?"])}
  },
  "items": {
    "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un nouvel element"])},
    "confirmDeleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer cet élément ?"])}
  },
  "errors": {
    "fieldCannotBeEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ ne peux être vide."])},
    "userDoesntExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet utilisateur n'existe pas."])}
  },
  "modals": {
    "shareInfo": {
      "message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cette liste vous à été partagé par <b>", _interpolate(_named("author")), "</b>. Vous ne pouvez la supprimer, mais vous pouvez y effectuer des modifications"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceci est une liste partagée"])}
    },
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À propos"])},
      "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer avec amour par"])},
      "foundABug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez trouvé un bug ? Ça arrive."])},
      "reportIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ouvrir une issue!"])},
      "softwareCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette application vous est offerte gratuitement, puisque je m'en sert personellement et que t'en qu'a m'en servir, je vais la partager avec le monde!"])},
      "showSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez m'aider dans le développement de cette app?"])},
      "buyMeACoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achetez-moi un café!"])},
      "contribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou bien vous pouvez contribuer sur"])}
    }
  },
  "shareWithUserModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partage de liste"])},
    "listTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste partagé avec"])},
    "revokeSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partage annuler avec succès"])},
    "successfullySentShareRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de partage envoyé avec succès."])},
    "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau partage"])},
    "form": {
      "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour partager cette liste avec quelqu'un, veuillez entrer son address courriel ici. Veuillez noter que l'adresse courriel doit être associé à un compte."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address courriel"])}
    }
  },
  "ShareWeekWithUserModal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partage de semaine"])},
    "form": {
      "headerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour partager toutes les semaines avec quelqu'un, veuillez entrer son address courriel ici. Veuillez noter que l'adresse courriel doit être associé à un compte."])}
    }
  },
  "shareRequest": {
    "item": {
      "list": {
        "textReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " veux partager la liste \"", _interpolate(_named("listName")), "\" avec vous."])},
        "textSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Demande envoyée à ", _interpolate(_named("targetName")), " pour la liste \"", _interpolate(_named("listName")), "\"."])}
      },
      "week": {
        "single": {
          "textReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " veux partager la semaine du ", _interpolate(_named("startDate")), " au ", _interpolate(_named("endDate")), " avec vous."])},
          "textSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Demande envoyée à ", _interpolate(_named("targetName")), " pour la semaine du ", _interpolate(_named("startDate")), " au ", _interpolate(_named("endDate")), "."])}
        },
        "all": {
          "textReceived": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("authorName")), " veux partager toutes les semaines avec vous."])},
          "textSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Demande envoyée à ", _interpolate(_named("targetName")), " pour toutes les semaines."])}
        }
      },
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
      "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
      "revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
    },
    "successfullyAcceptedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de partage accepté avec succès"])},
    "successfullyDeclinedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande de partage refusé avec succès"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes de partage"])},
    "receivedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande(s) reçue(s)"])},
    "issuedRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande(s) envoyée(s)"])}
  },
  "tabs": {
    "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semaine"])}
  },
  "WeekPlanner": {
    "titles": {
      "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dîner"])},
      "supper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souper"])},
      "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités"])}
    }
  },
  "shareWeekSelector": {
    "sharedWeek": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["semaine de ", _interpolate(_named("email"))])},
    "yourWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre semaine"])},
    "shareAllWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager toutes les semaines"])},
    "shareSpecificWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager une semaine en particulier"])}
  }
}